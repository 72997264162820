<template>
	<div class="notam">
		<div class="notam-col-1">
			<div class="notam-msg">
				{{message}}
			</div>
		</div>
		<div class="notam-col-2">
			<div class="notam-props">
				<div class="notam-prop">
					<h6>ID</h6>
					<p>{{notam.notamKey}}</p>
				</div>
				<div class="notam-prop">
					<h6 v-b-tooltip:t.auto="$t('notamView.dates')">
						<font-awesome-icon :icon="['fas', 'calendar-alt']" />
					</h6>
					<p>{{notam.startDate | formatDateTime}}</p>
					<div />
					<p>{{notam.endDate | formatDateTime}}</p>
				</div>
				<div class="notam-prop">
					<h6 v-b-tooltip:t.auto="$t('notamView.height')">
						H
						<font-awesome-icon :icon="['fas', 'arrows-alt-v']" />
					</h6>
					<p> {{notam.startHeight}} {{'m' | unitSuffix}} </p>
					<div />
					<p v-if="notam.endHeight">{{notam.endHeight}} {{'m' | unitSuffix}}</p>
					<p v-else>
						∞
						{{'m' | unitSuffix}}
					</p>
				</div>
				<div class="notam-prop">
					<h6 v-b-tooltip:t.auto="$t('notamView.qCode')">Q</h6>
					<p>{{notam.qCode}}</p>
				</div>
				<div class="notam-prop">
					<h6 v-b-tooltip:t.auto="$t('notamView.modifier')">
						<font-awesome-icon :icon="['fas', 'wrench']" />
					</h6>
					<p>{{notam.modifier}}</p>
				</div>
			</div>
			<SaveToBriefingButton class="mt-3" :added="addedToBriefing" @click="addNotamToBriefing(notam)" />
		</div>

	</div>
</template>

<script>
import SaveToBriefingButton from "@/components/SaveToBriefingButton.vue";
import briefingmanager from "@/plugins/briefingmanager";

export default {
	name: 'NotamCard',
	components: {SaveToBriefingButton},
	data: function () {
		return {
			addedToBriefing: false,
		}
	},
	props: {
		/** @type {Notam} */
		notam: Object,
	},
	computed: {
		message: function () {
			return this.notam.raw.split("\n")
				.slice(1, -2)
				.join("\n").trim();
		}
	},
	mounted: function () {
		this.addedToBriefing = ((briefingmanager.getGroups().find(g => g.key === "notam") || {}).subgroups || []).some(sg => sg.key === this.notam.notamKey.toLowerCase());
	},
	methods:{
		/** @param {Notam} notam */
		addNotamToBriefing(notam) {
			this.addedToBriefing = true;
			briefingmanager.addSubGroup("notam", {
				key: (notam.notamKey || ""),
				title: `
          <h2 style="text-align: center; margin: 0">${notam.notamKey}</h2>
        `,
				localTitle: notam.notamKey,
				tables: [
					{
						key: "fullnotam",
						rows: [
							[
								{
									style: "flex: 1; text-align: center;",
									value: `<span class="d-inline-block text-left my-2" style="white-space: pre-wrap;">${this.message}</span>`,
								}
							],
							[
								{
									style: "flex: 1; text-align: center; font-weight: bold;",
									value: "#{notamView.startDate}",
								},
								{
									style: "flex: 1; text-align: center;",
									value: window.formatDateTime(notam.startDate),
								},
								{
									style: "flex: 1; text-align: center; font-weight: bold;",
									value: "#{notamView.endDate}",
								},
								{
									style: "flex: 1; text-align: center;",
									value: window.formatDateTime(notam.endDate),
								},
							]
						]
					}
				]
			});
		}
	}
}
</script>

<style scoped>

.notam {
	display: flex;
	gap: 1rem;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 10px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.notam-col-1 {
	flex: 1;
}

.notam-col-2 {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: flex-start;
	justify-content: space-between;
}

.notam-msg {
	white-space: pre-wrap;
	font-size: 0.9rem;
}

.notam-props {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.notam-prop {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	justify-content: flex-start;
}

.notam-prop h6 {
	font-weight: bold;
	font-size: 0.8rem;
	margin: 0;
	text-align: right;
	min-width:20px;
}

.notam-prop p {
	font-size: 0.9rem;
	padding: 0.5rem 1rem;
	border-radius: 10px;
	background-color: #f0f0f0;
	margin: 0;
}

.notam-prop div {
	width: 12px;
	height: 3px;
	background-color: #000;
	border-radius: 99px;
}

@media (max-width: 768px) {
	.notam {
		flex-direction: column;
	}
	.notam-msg {
		font-size: 0.8rem;
	}
	.notam-col-1 {
		width: 100%;
	}
	.notam-col-2 {
		width: 100%;
	}


	.notam-prop h6 {
		font-size: 0.6rem;
	}
	.notam-prop p {
		font-size: 0.8rem;
		padding: 0.3rem 0.5rem;
	}
	.notam-prop div {
		width: 8px;
		height: 2px;
	}

	.notam-props {
		gap: 0.3rem;
	}

}
</style>
