<template>
  <div>
		<div class="filters-con">
			<div class="d-flex gap-1 align-items-center justify-content-center w-100 border-b">
				<div class="flex-column" style="max-width: 620px; width: 100%">
					<label class="d-block text-center" for="id">{{ $t('notamView.search') }}</label>
					<InputText id="id" v-model="filters.search" :placeholder="$t('notamView.searchPlaceholder')" class="w-100" />
				</div>
			</div>
			<div class="filters">
				<div class="filter">
					<label for="startDate">{{ $t('notamView.startDate') }}</label>
					<Calendar id="startDate" v-model="filters.from" panelClass="calendar-class"  :placeholder="startDatePlaceholder" dateFormat="yy.mm.dd"  />
				</div>
				<div class="filter">
					<label for="endDate">{{ $t('notamView.endDate') }}</label>
					<Calendar id="endDate" v-model="filters.to" panelClass="calendar-class" :placeholder="endDatePlaceholder" dateFormat="yy.mm.dd" />
				</div>
				<div class="filter">
					<label for="id">{{ $t('notamView.notamKey') }}</label>
					<InputText id="id" v-model="filters.id" placeholder="LHBP" />
				</div>
			</div>
			<div class="d-flex gap-1 align-items-center justify-content-center w-100">
				<label for="hidden" class="mb-0 text-muted">{{ $t('notamView.onlyActive') }}</label>
				<Checkbox id="hidden" v-model="filters.onlyActive" :binary="true" />
			</div>
		</div>

		<div class="notam-list">
			<NotamCard v-for="notam in notams" v-bind:key="notam.id" :notam="notam" />
		</div>
  </div>
</template>

<script>

import {LOCAL_STORAGE_SERVICE, STORAGE_KEY} from "@/plugins/utils";
import {FilterMatchMode} from 'primevue/api/';
import NotamCard from "@/components/NotamCard.vue";
import Checkbox from "primevue/checkbox";
import moment from "moment/moment";

export default {
    name: "NotamListView",
    data() {
        return {
						/** @type {Notam[]} */
						notams: [],
						/** @type {Notam[]} */
						allNotams: [],
            filters: {
							'search': '',
							'id': null,
							'from': null,
							'to': null,
							'onlyActive': true,
            },
						searchTimeout: null,
        };
    },
    props: {
        app: Object,
    },
		watch: {
			filters: {
				handler() {
					this.filter()
				},
				deep: true
			}
		},
		computed: {
			startDatePlaceholder(){
				return moment().format("YYYY.MM.DD");
			},
			endDatePlaceholder(){
				return moment().add(1, 'days').format("YYYY.MM.DD");
			},
		},
    async mounted() {
				const notams = await this.$rest.getNotams();
				this.allNotams = notams //.sort((a, b) => (a.notamKey.includes("LHCC") === b.notamKey.includes("LHCC")) ? 0 : a.notamKey.includes("LHCC") ? -1 : 1);
				this.notams = this.allNotams;
				this.filter();
		},
    components: { Checkbox, NotamCard },
		methods: {
			filter() {
				if (this.searchTimeout) {
					clearTimeout(this.searchTimeout);
				}

				this.searchTimeout = setTimeout(() => {
					this.notams = this.allNotams.filter(notam => {
						if (this.filters.id && !notam.notamKey.toLowerCase().includes(this.filters.id.toLowerCase())) {
							return false;
						}
						if (this.filters.from && moment(notam.startDate).isBefore(this.filters.from)) {
							return false;
						}
						if (this.filters.to && moment(notam.startDate).isAfter(this.filters.to)) {
							return false;
						}
						if (this.filters.onlyActive && (moment(notam.startDate).isAfter(moment()) || moment(notam.endDate).isBefore(moment()))) {
							return false;
						}

						if(this.filters.search && !notam.notamKey.toLowerCase().includes(this.filters.search.toLowerCase()) && !notam.raw.toLowerCase().includes(this.filters.search.toLowerCase())){
							return false;
						}
						return true;
					});
				}, 200);
			}
		}
}
</script>

<style>
.notam-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
}

.filters-con{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.2rem;
	margin-top: 2rem;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 0.5rem;
	margin-bottom: 2rem;
}

.filters{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1.2rem;
	width: 100%;
}

.filters .filter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}


@media screen and (max-width: 500px){
	.filters-con{
		flex-direction: column;
		width: 100%;
	}
	.filters{
		flex-direction: column;
		width: 100%;
	}

	.filters .filter {
		width: 100%;
	}
	.filters .filter input, .filters .filter .p-calendar {
		width: 100% !important;
		flex: 1;
	}
}




</style>
